<template>
  <div class="product-edit page-card-demo  page-info-content">
    <b v-if="dialogFrom.proCate!='04'" class="red_info">(请设置相应的费用项目，如不设置，可能会影响后续的费用征收)</b>
    <el-form ref="Forms" :model="dialogFrom" :inline="true" :rules="rules" label-width="132px" @submit.native.prevent>
      <!--      <el-form-item class="br-form-item-label" label-width="144px" label="产品编号：" prop="proId">-->
      <!--        <el-input type="input" class="br-input" v-model="dialogFrom.proId" disabled>-->
      <!--        </el-input>-->
      <!--      </el-form-item>-->
      <el-form-item class="br-form-item-label" label-width="" label="产品类别" prop="proCate">
        <el-select
          v-model="dialogFrom.proCate"
          clearable
          placeholder="请选择产品类别"
          filterable
          class="br-select"
          disabled
          @change="changeProCate"
          @clear="clearProCate"
        >
          <!--                     :disabled="!(dialogStaus==1||(dialogStaus==2&&proCheckFlag=='01'))"-->
          <el-option
            v-for="(item,index) in $select('proCate')"
            :key="index"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="br-form-item-label" label-width="" label="产品品种" prop="proType">
        <el-select
          v-model="dialogFrom.proType"
          clearable
          placeholder="请选择产品品种"
          filterable
          class="br-select"
          disabled
          @change="changeProType"
        >
          <!--                    :disabled="dialogFrom.proCate=='04' || !(dialogStaus==1||(dialogStaus==2&&proCheckFlag=='01'))"-->
          <el-option
            v-for="(item,index) in $childrenSelect('proCate',dialogFrom.proCate)"
            :key="index"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="br-form-item-label" label-width="" label="产品名称" prop="proName">
        <el-input v-model="dialogFrom.proName" type="input" class="br-input" placeholder="请选择产品名称" />
      </el-form-item>

      <el-form-item class="br-form-item-label" label-width="" label="核心企业" prop="coreEpId">
        <el-select v-model="dialogFrom.coreEpId" clearable placeholder="请选择核心企业" filterable class="br-select" :disabled="dialogStaus==2" @change="coreEpIdChange">
          <el-option
            v-for="(item,index) in coreEnterPriseList"
            :key="index"
            :value="item.cmpCode"
            :label="item.cmpName"
          />
        </el-select>
      </el-form-item>

      <el-form-item class="br-form-item-label" label="集团模式：" prop="groupType">
        <el-radio-group v-model="dialogFrom.groupType" class="br-radio" :disabled="dialogStaus==2" @change="groupTypeChange">
          <el-radio :label="1">
            是
          </el-radio>
          <el-radio :label="0">
            否
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="br-form-item-label" label="所属集团" prop="groupCmpName">
        <el-input v-model.trim="dialogFrom.groupCmpName" class="br-input" disabled />
      </el-form-item>

      <el-form-item class="br-form-item-label" label-width="" label="资金方" prop="proFundId">
        <el-select v-model="dialogFrom.proFundId" clearable filterable placeholder="请选择资金方" class="br-select" :disabled="dialogStaus==2" @change="proFundIdChange">
          <el-option
            v-for="(item,index) in cmpNameList"
            :key="index"
            :value="item.id"
            :label="item.funderName"
          />
        </el-select>
      </el-form-item>

      <el-form-item class="br-form-item-label" label-width="" label="资金方产品编号" prop="proFundNo">
        <el-select v-model="dialogFrom.proFundNo" clearable filterable placeholder="请选择资金方产品编号" class="br-select" :disabled="dialogStaus==2" @change="proFundNoChange">
          <el-option
            v-for="(item,index) in fundProdNoList"
            :key="index"
            :value="item.key"
            :label="item.key"
          />
        </el-select>
      </el-form-item>

      <el-form-item class="br-form-item-label" label-width="" label="资金方业务主体编号" prop="proFundMainNo">
        <el-input v-model="dialogFrom.proFundMainNo" type="input" class="br-input" maxlength="30" disabled />
      </el-form-item>
      <el-form-item class="br-form-item-label" label-width="" label="版本号" prop="bankVersionName">
        <el-input v-model="dialogFrom.bankVersionName" type="input" class="br-input" maxlength="30" disabled />
      </el-form-item>
      <el-form-item class="br-form-item-label" label-width="" label="付息方式" prop="interstType">
        <el-select v-model="dialogFrom.interstType" placeholder="请选择资付息方式" filterable class="br-select" disabled>
          <el-option
            v-for="(item,index) in intersTypeList"
            :key="index"
            :value="item.dictId"
            :label="item.dictName"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="br-form-item-label" label-width="" label="授信来源" prop="proFundRes">
        <el-select v-if="dialogFrom.proCate == '04'" v-model="dialogFrom.proFundRes" placeholder="请选择授信来源" filterable class="br-select" disabled>
          <el-option
            v-for="(item,index) in $select('proFundRes').slice(1)"
            :key="index"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
        <el-select v-else v-model="dialogFrom.proFundRes" placeholder="请选择授信来源" filterable class="br-select">
          <el-option
            v-for="(item,index) in $select('proFundRes').slice(1)"
            :key="index"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-show="proTypeShow"
        v-if="dialogFrom.proCate!='04'"
        class="br-form-item-label"
        label-width=""
        label="明暗标识"
        prop="proMaflag"
      >
        <el-select v-model="dialogFrom.proMaflag" placeholder="请选择明暗标识" filterable class="br-select" disabled>
          <el-option
            v-for="(item,index) in $select('proMaFlag')"
            :key="index"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        v-show="proTypeShow"
        v-if="dialogFrom.proCate!='04'"
        class="br-form-item-label"
        label-width=""
        label="有无追索权"
        prop="proZsflag"
      >
        <el-select v-model="dialogFrom.proZsflag" placeholder="请选择有无追索权" filterable class="br-select" disabled>
          <el-option
            v-for="(item,index) in $select('proZsFlag')"
            :key="index"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="dialogFrom.proCate!='04'"
        class="br-form-item-label"
        label-width=""
        label="融资单据类型"
        prop="proBillType"
      >
        <el-select v-model="dialogFrom.proBillType" placeholder="请选择融资单据类型" disabled filterable class="br-select">
          <el-option
            v-for="(item,index) in $select('billType')"
            :key="index"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="dialogFrom.proCate!='04'"
        class="br-form-item-label"
        label-width=""
        label="融资期限范围"
        prop="fundRangeMin"
      >
        <el-input
          v-model="dialogFrom.fundRangeMin"
          class="br-input br-input-small"
          oninput="value=value.replace(/[^\d]/g,'');"
          @change="handleMinChange"
        />
      </el-form-item>
      <el-form-item
        v-if="dialogFrom.proCate!='04'"
        class="br-form-item-label"
        label="~"
        label-width="14px"
        prop="fundRangeMax"
        style="margin-right:0;"
      >
        <el-input
          v-model="dialogFrom.fundRangeMax"
          class="br-input br-input-small"
          oninput="value=value.replace(/[^\d]/g,'');"
          @change="handleMaxChange"
        />
        <span class="br-right-label">天</span>
      </el-form-item>

      <el-form-item
        v-if="dialogFrom.proCate!='04'"
        class="br-form-item-label"
        label-width=""
        label="额度最晚启用期(天)"
        prop="proCreditLastDate"
      >
        <el-input v-model="dialogFrom.proCreditLastDate" class="br-input " />
      </el-form-item>
      <el-form-item
        v-if="dialogFrom.proCate!='04'"
        class="br-form-item-label"
        label-width=""
        label="是否允许提前还款"
        prop="proPrepaymentFlag"
      >
        <el-select v-model="dialogFrom.proPrepaymentFlag" filterable class="br-select" disabled>
          <el-option
            v-for="(item,index) in $select('proPrepaymentFlag')"
            :key="index"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>
      <!--      <el-form-item class="br-form-item-label" label-width="" label="是否宽限" prop="proGraceFlag"-->
      <!--                    v-if="dialogFrom.proCate!='04'">-->
      <!--        <el-select v-model="dialogFrom.proGraceFlag" filterable class="br-select" @change="changeProGraceFlag">-->
      <!--          <el-option v-for="(item,index) in $select('proGraceFlag')" :value="item.value" :label="item.text"-->
      <!--                     :key="index"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->

      <!--      <el-form-item class="br-form-item-label" label-width="" label="宽限期限(天)" prop="proGraceTerm"-->
      <!--                    v-if="dialogFrom.proCate!='04'"-->
      <!--                    :rules="{required:dialogFrom.proGraceFlag == '1',message: '请输入宽限期限',validator: checkProGraceTerm,trigger: 'blur'}">-->
      <!--        <el-input class="br-input " v-model="dialogFrom.proGraceTerm"-->
      <!--                  :disabled="dialogFrom.proGraceFlag=='0'"></el-input>-->
      <!--      </el-form-item>-->
      <el-form-item
        v-if="dialogFrom.proCate!='04'"
        class="br-form-item-label"
        label-width=""
        label="还款方式"
        prop="proPaymentWay"
      >
        <el-select v-model="dialogFrom.proPaymentWay" class="br-select" filterable disabled placeholder="请选择">
          <el-option
            v-for="(item,index) in RETUKIND"
            :key="index"
            :value="item.dictId"
            :label="item.dictName"
          />
        </el-select>
      </el-form-item>

      <!--            <el-form-item class="br-form-item-label" label-width="" label="融资协议" prop="agree3"-->
      <!--                          v-if="dialogFrom.proCate!='04'">-->
      <!--                <el-select v-model="dialogFrom.agree3" filterable value-key="compactName" class="br-select" @change="agree3Change($event,'融资协议',3)">-->
      <!--                    <el-option v-for="(item,index) in proCompact3" :value="item" :label="item.compactName"-->
      <!--                               :key="index.compactId"></el-option>-->
      <!--                </el-select>-->
      <!--            </el-form-item>-->
      <template v-if="dialogFrom.proCate!='04'">
        <el-form-item
          v-for="(item,index) in fileNameList"
          :key="index"
          class="br-form-item-label"
          label-width=""
          :label="item.dictName"
          :prop="'agree'+item.dictId"
          :rules="rules.fileListName"
        >
          <el-select v-model="dialogFrom['agree'+item.dictId]" filterable value-key="compactId" class="br-select" @change="agree3Change($event,item.dictName,item.dictId)">
            <el-option
              v-for="(item1,index1) in fileList[item.dictId]"
              :key="index1.compactId"
              :value="item1"
              :label="item1.compactName"
            />
          </el-select>
        </el-form-item>
      </template>

      <!--      <el-form-item class="br-form-item-label" label-width="" label="应收账款转让通知书" v-show="proTypeShow" prop="agree2"-->
      <!--                    v-if="dialogFrom.proCate!='04'">-->
      <!--        <el-select v-model="dialogFrom.agree2" filterable value-key="compactName" class="br-select">-->
      <!--          <el-option v-for="(item,index) in proCompact2" :value="item" :label="item.compactName"-->
      <!--                     :key="index.compactId"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item class="br-form-item-label" label-width="" label="是否支持多级" prop="proEchainFlag">
        <el-radio-group v-model="dialogFrom.proEchainFlag" @change="proEchainFlagChange">
          <el-radio :label="1">
            是
          </el-radio>
          <el-radio :label="0">
            否
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="dialogFrom.proEchainFlag==1"
        class="br-form-item-label"
        label-width=""
        label="多级融资协议"
        prop="agree10"
      >
        <el-select v-model="dialogFrom.agree10" filterable value-key="compactId" class="br-select">
          <el-option
            v-for="(item,index) in proCompact5"
            :key="index.compactId"
            :value="item"
            :label="item.compactName"
            @change="agree3Change($event,'多级融资协议',10)"
          />
        </el-select>
      </el-form-item>
      <br>
      <el-form-item
        v-if="dialogFrom.proCate!='04'"
        class="br-form-item-label"
        label-width=""
        label="单笔融资限额"
        prop="singleFinanceAmountMin"
      >
        <el-input
          v-model="dialogFrom.singleFinanceAmountMin"
          class="br-input "
          oninput="value=value.replace(/[^\d]/g,'');"
          @change="handleMinChange1"
        />
      </el-form-item>
      <el-form-item
        v-if="dialogFrom.proCate!='04'"
        class="br-form-item-label"
        label="~"
        label-width="14px"
        prop="singleFinanceAmount"
        style="margin-right:0;"
      >
        <el-input
          v-model="dialogFrom.singleFinanceAmount"
          class="br-input "
          oninput="value=value.replace(/[^\d]/g,'');"
          @change="handleMaxChange1"
        />
        <span class="br-right-label">元</span>
      </el-form-item>
      <br>
      <!--      <el-form-item class="br-form-item-label" label-width="" label="应收账款转让协议" v-show="proTypeShow" prop="agree1"-->
      <!--                    v-if="dialogFrom.proCate!='04'">-->
      <!--        <el-select v-model="dialogFrom.agree1" filterable value-key="compactName" class="br-select">-->
      <!--          <el-option v-for="(item,index) in proCompact1" :value="item" :label="item.compactName"-->
      <!--                     :key="index.compactId"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->

      <!--      <el-form-item class="br-form-item-label" label-width="" label="法律文本" v-show="proTypeShow" prop="agree4"-->
      <!--                    v-if="dialogFrom.proCate!='04'">-->
      <!--        <el-select v-model="dialogFrom.agree4" filterable value-key="compactName" class="br-select">-->
      <!--          <el-option v-for="(item,index) in proCompact4" :value="item" :label="item.compactName"-->
      <!--                     :key="index.compactId"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <div v-if="dialogFrom.proCate!='04'" class="parameTitle">
        <span class="leftLine">&nbsp;</span>
        <span class="rightText">费用-资金方</span>
      </div>
      <el-form-item
        v-if="dialogFrom.proCate!='04'"
        class="br-form-item-label"
        label-width="132px"
        label="费用配置方式"
        prop="fundConfigType"
      >
        <el-select v-model="dialogFrom.fundConfigType" disabled class="br-select" @change="fundConfigTypeChange">
          <el-option :value="0" label="从接口读取" />
          <el-option :value="1" label="平台配置" />
        </el-select>
      </el-form-item>

      <el-table
        v-if="dialogFrom.proCate!='04' && dialogFrom.fundConfigType==1"
        :border="true"
        :data="costCapitalSideList"
        class="br-table"
        style="width: 100%"
      >
        <el-table-column label="费用名称" prop="costName" />
        <el-table-column label="收取时点" prop="collectTime" />
        <el-table-column label="名称定义" prop="nameDefinition" width="250" />
        <el-table-column label="收取方式(年化率)" width="350">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.numflag"
              v-model="scope.row.num"
              class="br-input br-input-small"
              @blur="checkCostByPlatform(scope.row.num,scope.row.collectType)"
            />

            <el-select
              v-if="scope.row.numflag"
              v-model="scope.row.collectType"
              class="br-select br-select-medium"
              placeholder=""
            >
              <template v-for="(item,index) in $select('collectTypeTwo').slice(0,1)">
                <el-option
                  v-if="scope.row.costName=='逾期费'||scope.row.costName=='宽限期手续费'"
                  :key="index"
                  :value="item.value"
                  :label="item.text"
                />
                <el-option
                  v-for="(optionItem,optionIndex) in $select('collectTypeTwo')"
                  v-else
                  :key="index + optionIndex"

                  :value="optionItem.value"
                  :label="optionItem.text"
                />
              </template>
            </el-select>

            <el-select
              v-if="scope.row.costName=='保理费'"
              v-model="scope.row.factoringType"
              class="br-select"
              placeholder=""
            >
              <el-option
                v-for="(item,index) in $select('factoringType').slice(0,1)"
                :key="index"
                :value="item.value"
                :label="item.text"
              />
            </el-select>

            <span v-if="scope.row.costName=='融资利息'" style="margin-right:10px;">是否前置收取</span>

            <el-select v-if="scope.row.costName=='融资利息'" v-model="scope.row.preCharge" class="br-select" placeholder="">
              <el-option
                v-for="(item,index) in $select('preCharge')"
                :key="index"
                :value="item.value"
                :label="item.text"
              />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="dialogFrom.proCate!='04'" class="parameTitle">
        <span class="leftLine">&nbsp;</span>
        <span class="rightText">费用-平台</span>
      </div>
      <div class="add-platfrom-btn">
        <el-button type="primary" size="small" class="br-btn-large br-submit-btn" @click="addPlatfrom">
          新增
        </el-button>
      </div>
      <el-table class="br-table" :border="true" :data="dialogFrom.flist" :empty-text="dialogStaus=='2'?'':'请 新增填写行 进行填写'">
        <el-table-column label="费用名称" prop="costTypeList" min-width="150">
          <template slot="header">
            <span style="color: #f56c6c">*</span>费用名称
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="'flist.' + scope.$index + '.costTypeList'" :rules="rules.costTypeList">
              <el-select v-model="scope.row.costTypeList" value-key="dictId" placeholder="请选择费用名称" filterable class="platfrom-input" @change="costTypeChange($event,scope.row)">
                <el-option v-for="(item,index) in prodCostTypePlat" :key="index" :value="item" :label="item.dictName" />
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="收取时点" prop="costGentime" min-width="150">
          <template slot-scope="scope">
            <el-form-item :prop="'flist.' + scope.$index + '.costGentime'" :rules="rules.costGentime">
              <el-select v-model="scope.row.costGentime" placeholder="请选择收取时点" filterable class="platfrom-input">
                <el-option v-for="(item,index) in $select('costGentime')" :key="index" :value="item.value" :label="item.text" />
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="收取方式" prop="costNum" min-width="240">
          <template slot="header">
            <span style="color: #f56c6c">*</span>收取方式
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="'flist.' + scope.$index + '.costNum'" :rules="rules.costNum">
              <el-input v-model="scope.row.costNum" class="br-input mini-input" oninput="!/^(\d+\.?)?\d{0,5}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''" @blur="checkCostByPlatform(scope.row.costNum,scope.row.costUnit)" />
              <el-select v-model="scope.row.costUnit" placeholder="" filterable class="br-select mini-select" @change="costUnitChange(scope.row)">
                <el-option v-for="(item,index) in $select('collectType').slice(1)" :key="index" :value="item.value" :label="item.text" />
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="最低收取" prop="minimum" min-width="150">
          <template slot="header">
            <span style="color: #f56c6c">*</span>
            最低收取
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="'flist.' + scope.$index + '.minimum'" :rules="rules.minimum">
              <el-input
                v-model.trim="scope.row.minimum"
                class="platfrom-input"
                clearable
                oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
                :disabled="scope.row.costUnit=='01'"
                placeholder="最低收取"
                @blur="checkMinimum(scope.row.minimum,scope.row.costUnit)"
              />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="支付方" prop="costPayer" min-width="150">
          <template slot="header">
            <span style="color: #f56c6c">*</span>支付方
          </template>
          <template slot-scope="scope">
            <el-form-item :prop="'flist.' + scope.$index + '.costPayer'" :rules="rules.costPayer">
              <el-select v-model="scope.row.costPayer" placeholder="请选择支付方" filterable class="platfrom-input">
                <el-option v-for="(item,index) in payer" :key="index" :value="item.dictId" :label="item.dictName" />
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="操作" min-width="50">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="delPlatfrom(scope.$index,scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-form>

    <div class="br-footer">
      <el-button size="small" class="br-btn-large br-cancel-btn" @click="handleCancel">
        取消
      </el-button>
      <el-button v-if="dialogStaus==1||(dialogStaus==2&&proCheckFlag=='01')" size="small" type="primary" class="br-btn-large br-submit-btn" :loading="btnloading" @click="handleSave">
        保存
      </el-button>
      <el-button size="small" type="primary" class="br-btn-large br-submit-btn" :loading="btnloading" @click="handleSubmit">
        提交
      </el-button>
    </div>
  </div>
</template>
<script>
// , AddProduct, AddSubmitProduct, selectCmpName
import { getProCompact, selectCmpName, getFinancialSideList, UpdateFrontProduct, UpdateSubmitProduct, UpdateProduct } from '@/api/tradeFinancing'

export default {
  data() {
    return {
      cosePlatfromVal: {
        costId: 0, // 新增的时候写死
        costName: null, // 费用名称
        costTypeList: null,
        costPayer: null, // 支付方
        costType: null, // 费用类型
        costGentime: null, // 收取时点
        costNum: null, // 收取方式
        costUnit: '02',
        minimum: null // 最低收取
      },
      proTypeShow: false,
      btnloading: false,
      dialogStaus: '1', // 1新增 2修改--字符
      costCapitalSideList: [],
      proCompact5: [],
      fileNameList: [],
      fileList: {},
      RETUKIND: this.$store.getters.getDictionaryItem('RETUKIND'),
      // fundProdNoList: this.$store.state.tradeFinancing.coreEnterPriseList,
      cmpNameList: [],
      // coreEnterPriseList: [],
      dialogFrom: {
        flist: [] // 费用-平台
      },
      rules: {
        proName: [
          { required: true, trigger: 'blur', message: '请输入产品名称' },
          {
            validator: this.checkProName,
            trigger: 'blur'
          }
        ],
        proCate: [{ required: true, trigger: 'change', message: '请选择产品类别' }],
        proType: [{ required: true, trigger: 'change', message: '请选择产品品种' }],
        coreEpId: [{ required: true, trigger: 'change', message: '请选择核心企业' }],
        proFundId: [{ required: true, trigger: 'change', message: '请选择资金方' }],
        proFundNo: [{ required: true, trigger: ['blur', 'change'], message: '请输入资金方产品编号' }],
        proFundMainNo: [{ required: true, trigger: ['blur', 'change'], message: '请输入资金方业务主体编号' }],
        proBillType: [
          { required: true, trigger: 'change', message: '请选择融资单据类型' }
        ],
        proCreditLastDate: [
          // {required: true, trigger: "blur", message: "请输入额度最晚使用期"},
          {
            validator: this.checkDay,
            trigger: 'blur'
          }
        ],
        proPrepaymentFlag: [
          { required: true, trigger: 'change', message: '请选择是否允许提前还款' }
        ],
        fundRangeMin: [
          { required: true, trigger: 'blur', message: '不能为空' },
          { validator: this.validateCom, trigger: 'blur' },
          { validator: this.validateMin, trigger: 'blur' }
        ],
        fundRangeMax: [
          { validator: this.validateCom, trigger: 'blur' },
          { validator: this.validateMax, trigger: 'blur' }
        ],
        singleFinanceAmountMin: [
          { required: true, trigger: 'blur', message: '不能为空' },
          { validator: this.validateCom, trigger: 'blur' },
          { validator: this.validateMin1, trigger: 'blur' }
        ],
        singleFinanceAmount: [
          { validator: this.validateCom, trigger: ['blur'] },
          { validator: this.validateMax1, trigger: ['blur'] }
        ],
        agree10: [{ required: true, trigger: 'change', message: '不能为空' }],
        fileListName: [{ required: true, trigger: 'change', message: '不能为空' }],
        proPaymentWay: [
          { required: true, trigger: 'change', message: '请选择还款方式' }
        ],
        costTypeList: [{ required: true, trigger: ['blur', 'change'], message: '请选择费用名称' }],
        costPayer: [{ required: true, trigger: ['blur', 'change'], message: '请选择支付方' }],
        costNum: [{ required: true, trigger: ['blur', 'change'], message: '请输入收取方式' }, { validator: this.validateCostNum, trigger: ['blur', 'change'] }],
        minimum: [{ validator: this.validateMinimum, trigger: ['blur', 'change'] }]
      },
      dialogDataByProps: {
        proCate: '01', // 产品类别--20200904原型不可编辑
        proType: '0106', // 产品品种--20200904原型不可编辑
        proFundRes: '02', // 授信来源
        proMaflag: '1', // 明暗标识
        proZsflag: '0', // 有无追索权
        // proGraceFlag: "0", //是否宽限
        fundConfigType: 0, // 费用配置方式--20200904原型不可编辑
        proBillType: '14', // proBillType 融资单据类型--20200904原型不可编辑
        proPrepaymentFlag: '0', // 是否允许提前还款--20200904原型不可编辑
        proPaymentWay: '04', // 还款方式(一次性还款)--20200904原型不可编辑
        proEchainFlag: 0, // 是否支持多级协议
        groupType: 0
      }
    }
  },
  computed: {
    coreEnterPriseList() {
      // console.log('this.$store.state.tradeFinancing.coreEnterPriseList', this.$store.state.tradeFinancing.coreEnterPriseList)
      return this.$store.state.tradeFinancing.coreEnterPriseList
    },
    fundProdNoList() {
      return this.$store.state.tradeFinancing.fundProdNoList
    },
    bankVersionList() {
      return this.$store.getters.getDictionaryItem('BANK_VERSION')
    },
    intersTypeList() {
      return this.$store.getters.getDictionaryItem('INTERS_TYPE')
    },
    prodCostTypePlat() {
      return this.$store.getters.getDictionaryItem('PROD_COST_TYPE_PLAT')
    },
    payer() {
      return this.$store.getters.getDictionaryItem('PAYER')
    }

  },
  created() {
    this.in()
    console.log(this.$select('proCate').slice(1))
  },
  methods: {
    queryProCompact(params) {
      return new Promise((resolve) => {
        getProCompact(params, res => {
          // this.proCompact5 = res.data
          resolve(res.data)
        })
      })
    },
    validateMin(rule, value, callback) {
      const one = Number(value)
      const max = Number(this.dialogFrom['fundRangeMax'])
      if (!max || one <= max) {
        return callback()
      }
      return callback(new Error('不符规范'))
    },

    validateMax(rule, value, callback) {
      const one = Number(value)
      const min = Number(this.dialogFrom['fundRangeMin'])
      if ((min && one >= min) || (min === 0 && one > min)) {
        return callback()
      }
      return callback(new Error('不符规范'))
    },
    validateMin1(rule, value, callback) {
      const one = Number(value)
      const max = Number(this.dialogFrom['singleFinanceAmount'])
      if (!max || one <= max) {
        return callback()
      }
      return callback(new Error('不符规范'))
    },

    validateMax1(rule, value, callback) {
      const one = Number(value)
      const min = Number(this.dialogFrom['singleFinanceAmountMin'])
      if ((min && one >= min) || (min === 0 && one > min)) {
        return callback()
      }
      return callback(new Error('不符规范'))
    },

    validateCom(rule, value, callback) {
      const one = Number(value)
      if (Number.isInteger(one)) {
        if (one < this.MIN_NUMBER) {
          return callback(new Error(`输入值必须大于${this.MIN_NUMBER}`))
        }
        return callback()
      } else if (!value) {
        return callback(new Error('不能为空'))
      }
      return callback(new Error('必须为正整数'))
    },
    checkDay(rule, value, callback) {
      if (!value) {
        // callback(new Error("请输入额度最晚使用期"));
        callback()
      } else if (value === '0') {
        callback(new Error('最晚使用期不能为0'))
      } else if (!/^(\d+)$/.test(value)) {
        callback(new Error('请输入正确的最晚使用期'))
      } else {
        callback()
      }
    },
    validateMinimum(rule, value, callback) {
      this.checkMinimumValidate(rule, value, callback)
    }, // 最低收取
    validateCostNum(rule, value, callback) {
      this.checkCostNumValidate(rule, value, callback)
    }, // 收取方式
    costTypeChange(e, row) {
      row['costType'] = e['dictId']
      row['costName'] = e['dictName']
    },
    agree3Change(e, name, code) {
      // console.log('-----')
      // console.log(e,name,code);
      // console.log(this.dialogFrom);
      // console.log(this.dialogFrom);
      this.dialogFrom['agree' + code]['fileName'] = name
      // console.log(this.dialogFrom);
      this.$forceUpdate()
    },
    proEchainFlagChange(e) {
      if (!e) {
        delete this.dialogFrom['agree10']
      }
    },
    queryFileName(name) {
      this.fileNameList = this.$store.getters.getDictionaryItem(name)
      this.fileNameList.forEach(item => {
        this.queryFileList(item['dictId'])
      })
    },
    async in() {
      // 产品配置合同模板
      this.proCompact5 = await this.queryProCompact({ compactType: '10' })
      console.log('this.proCompact5', this.proCompact5)
      this.init((flist) => {
        // this.dialogFrom = Object.assign({}, this.dialogDataByProps);
        this.dialogFrom = JSON.parse(JSON.stringify(this.dialogDataByProps))
        this.dialogFrom.flist = flist // 费用-平台
        // this.dialogFrom.dlist=[];//费用-平台 删掉的id数组
        // this.queryStartFuncList(); // 请求资金方列表
        if (!this.$store.state.tradeFinancing.coreEnterPriseList.length) {
          this.$store.dispatch('tradeFinancing/queryCmpListByCmpRoleList')
        }

        console.log(this.dialogFrom, '000')
        // 编辑
        if (this.dialogFrom['compactList'].length > 0) {
          console.log(this.dialogFrom['compactList'], '001')
          this.dialogFrom['compactList'] = JSON.parse(JSON.stringify(this.dialogFrom['compactList']).replace(/compactCode/g, 'compactId'))
          console.log(this.dialogFrom, '002')
          this.dialogFrom['compactList'].forEach((item, val) => {
            this.dialogFrom['agree' + item.compactType] = item
            if (item.compactType === '10') {
              this.dialogFrom['agree' + item.compactType].fileName = '多级融资协议'
            }
          })
        }

        console.log(this.dialogFrom, '111')
        if (this.coreEnterPriseList.length > 0) {
          this.dialogFrom['coreEpUnicode'] = this.coreEnterPriseList.filter(item => {
            return item['cmpCode'] === this.dialogFrom['coreEpId']
          })[0]['cmpUnicode']
        }
        console.log(this.dialogFrom, '1112')
        // 集团模式是否两个接口查资金方
        if (this.dialogFrom['groupCmpName']) {
          const params = {
            coreCmpUnicode: this.dialogFrom.coreEpUnicode
          }
          this.selectGroupListFn(params).then(res => {
            if (res.code === 200) {
              if (!res.data[0].groupCmpUnicode) {
                this.$message({
                  type: 'error',
                  message: '集团未配置商圈'
                })
                // this.saveFundProdNoListFn([])
                return
              }
              this.dialogFrom.groupCmpName = res.data[0].groupCmpName
              this.dialogFrom.groupCmpUnicode = res.data[0].groupCmpUnicode
              // 资金方list
              this.cmpNameList = res.data.map(item => {
                return {
                  ...item,
                  id: item.id + ''
                }
              })
              if (this.cmpNameList.length > 0) {
                this.dialogFrom['proFundUnicode'] = this.cmpNameList.filter(item => {
                  return item['id'] === this.dialogFrom['proFundId']
                })[0]['cmpUnicode']
              }
              this.$forceUpdate()
            }
          })
        } else {
          const param = {
            coreCmpUnicode: this.dialogFrom.coreCmpUnicode
          }
          selectCmpName(param, res => {
            if (res.code === 200) {
              this.cmpNameList = res.data.map(item => {
                return {
                  ...item,
                  id: item.id + ''
                }
              })
              if (this.cmpNameList.length > 0) {
                this.dialogFrom['proFundUnicode'] = this.cmpNameList.filter(item => {
                  return item['id'] + '' === this.dialogFrom['proFundId']
                })[0]['cmpUnicode']
              }
            }
          })
        }
        console.log(this.dialogFrom, '2222')

        this.fileNameList = []
        this.dialogFrom['bankVersion'] && this.queryFileName(this.dialogFrom['bankVersion'])
        // this.fileNameList.forEach(r=>{
        //     this.queryFileList(r['dictId']);
        // });
        this.$forceUpdate()
        console.log('编辑', this.dialogFrom)
        this.costCapitalSideList = [
          {
            costName: '逾期费',
            collectTime: '逾期时收取',
            nameDefinition: '未按期还款的处罚金',
            collectType: this.dialogFrom['proCosta'] && this.dialogFrom['proCosta'].split(',')[1],
            num: this.dialogFrom['proCosta'] && this.dialogFrom['proCosta'].split(',')[0],
            numflag: true,
            val: 'a'
          },
          {
            costName: '提前还款手续费',
            collectTime: '提前还款时收取',
            nameDefinition: '违反合同约定的处罚金',
            collectType: this.dialogFrom['proCostb'] && this.dialogFrom['proCostb'].split(',')[1],
            num: this.dialogFrom['proCostb'] && this.dialogFrom['proCostb'].split(',')[0],
            numflag: true,
            val: 'b'
          },
          {
            costName: '宽限期手续费',
            collectTime: '展期时收取',
            nameDefinition: '宽限期（展期）时向融资方收取的费用',
            collectType: this.dialogFrom['proCostd'] && this.dialogFrom['proCostd'].split(',')[1],
            num: this.dialogFrom['proCostd'] && this.dialogFrom['proCostd'].split(',')[0],
            numflag: true,
            val: 'd'
          },
          {
            costName: '催收费',
            collectTime: '催收时收取',
            nameDefinition: '产生逾期后催款费用（委托催收、司法诉讼）',
            collectType: this.dialogFrom['proCoste'] && this.dialogFrom['proCoste'].split(',')[1],
            num: this.dialogFrom['proCoste'] && this.dialogFrom['proCoste'].split(',')[0],
            numflag: true,
            val: 'e'
          },
          {
            costName: '保理费',
            collectTime: '放款时收取',
            nameDefinition: '保理费',
            collectType: '00',
            num: '0',
            numflag: false,
            val: 'f',
            factoringType: this.dialogFrom['proCostf']
          },
          {
            costName: '融资利息',
            collectTime: '放款/还款时收取',
            nameDefinition: '融资利息',
            collectType: '00',
            num: '0',
            numflag: false,
            val: 'c',
            preCharge: this.dialogFrom['preCharge']
          }
        ]
      })
    },
    init(callback) {
      // this.queryProCompact({compactType: "06"});
      // this.queryProCompact({compactType: "02"});
      // this.queryProCompact({compactType: "03"});
      // this.queryProCompact({compactType: "04"});
    //   this.queryProCompact({ compactType: '10' })
      // this.queryStartCostList({
      //     id: ""
      // });
      this.id = this.$route.query.id || ''
      this.proCheckFlag = this.$route.query.proCheckFlag || ''
      this.dialogStaus = this.id ? '2' : '1'// 1新增 2修改--字符
      // 1新增 2修改--字符
      UpdateFrontProduct({ id: this.id }, res => {
        if (res.code === 200) {
          res.data.flist.forEach((item, index) => {
            delete item.costOwner
            delete item.costRemark
            delete item.costUserange
            delete item.costState
            item['costTypeList'] = {
              dictId: item.costType,
              dictName: item.costName
            }
          })
          this.dialogDataByProps = {
            ...res.data.clist[0],
            groupType: res.data.clist[0]['groupCmpName'] ? 1 : 0,
            compactList: res.data.compactList,
            interstType: res.data.clist[0]['interstType'].toString(),
            bankVersionName: this.$codeNew(this.bankVersionList, res.data.clist[0]['bankVersion']),
            flist: res.data.flist// 费用-平台
          }
          console.log(this.bankVersionList)
          callback(res.data.flist)
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    checkMinimumValidate(rule, value, callback) {
      const index = rule.field.split('.')[1] * 1
      const row = this.dialogFrom.flist[index]
      if (row.costUnit !== '01') {
        if (value === '' || value === null || value === undefined) {
          callback(new Error('请输入最低收取'))
        } else if (value && isNaN(Number(value))) {
          callback(new Error('请输入正确的最低收取'))
        } else if (value &&
                    value.toString().charAt('0') === '0' &&
                    value.toString().charAt('1') !== '.' &&
                    value.length > 1
        ) {
          callback(new Error('请输入正确的最低收取'))
        } else if (value && !/^(\d+)(\.\d{1,5})?$/.test(value)) {
          callback(new Error('请保留1-5位小数'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    checkCostNumValidate(rule, value, callback) {
      const index = rule.field.split('.')[1] * 1
      const row = this.dialogFrom.flist[index]
      const unit = row.costUnit
      if (value === '' || value === undefined || value === null) {
        callback(new Error('请输入收取方式'))
      } else if (isNaN(Number(value))) {
        callback(new Error('请输入正确的收取方式'))
      } else if (
        value.toString().charAt('0') === '0' &&
                value.toString().charAt('1') !== '.' &&
                value.length > 1
      ) {
        callback(new Error('请输入正确的收取方式'))
      } else if (unit === '01') {
        // 单位：笔
        if (!/^(\d+)(\.\d{1,2})?$/.test(value)) {
          callback(new Error('请保留1-2位小数'))
        } else if (value.length - 1 > 18) {
          callback(new Error('金额不得超过18位'))
        } else {
          callback()
        }
      } else {
        // 单位：比例
        if (!/^(\d+)(\.\d{1,5})?$/.test(value)) {
          callback(new Error('请保留1-5位小数'))
        } else if (value > 100) {
          callback(new Error('比例不能大于100'))
        } else if (value.length - 1 > 8) {
          callback(new Error('比例不得超过8位'))
        } else {
          callback()
        }
      }
    },
    // 新增费用-平台
    addPlatfrom() {
      if (this.dialogFrom.flist.length < 10) {
        this.dialogFrom.flist.push({
          ...JSON.parse(JSON.stringify(this.cosePlatfromVal))
        })
      } else {
        this.$message({
          type: 'info',
          message: '最多添加10条平台费用'
        })
      }
    },
    // 费用-平台-删除
    delPlatfrom(index, row) {
      const isEmpty = this.checkIsEmpty(row)
      if (isEmpty) {
        this.deleteRow(index, row)
      } else {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteRow(index, row)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }
    },
    // 判断对象里是否都为空，合同和发票两种对象
    checkIsEmpty(row) {
      const newRow = JSON.parse(JSON.stringify(row))
      // console.log(newRow);
      return this.objectKeyIsEmpty(newRow)
    },

    // 判断对象属性是否为空
    objectKeyIsEmpty(obj) {
      let empty = null
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          if (obj[key] === null || obj[key] === '' || obj[key].length === 0 || obj[key] === [] || obj[key] === 0) {
            empty = true
          } else {
            empty = false
            break
          }
        }
      }
      return empty
    },

    // 删除表格行
    deleteRow(index, row) {
      if (this.dialogFrom.flist.length <= 1) {
        this.$message({
          type: 'warning',
          message: '不得少于一条'
        })
      } else {
        // if(row.id)this.dialogFrom.dlist.push(row.id);
        this.dialogFrom.flist.splice(index, 1)
      }
    },
    getStrLen(str) {
      var len = 0
      for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127 || str.charCodeAt(i) === 94) {
          len += 2
        } else {
          len++
        }
      }
      return len
    },

    handleMinChange() {
      this.$refs['Forms']['validateField']('fundRangeMax')
    },

    handleMaxChange() {
      this.$refs['Forms']['validateField']('fundRangeMin')
    },

    handleMinChange1() {
      this.$refs['Forms']['validateField']('singleFinanceAmount')
    },

    handleMaxChange1() {
      this.$refs['Forms']['validateField']('singleFinanceAmountMin')
    },
    // 费用配置方式 change
    fundConfigTypeChange(e) {
      this.costCapitalSideList = [
        {
          costName: '逾期费',
          collectTime: '逾期时收取',
          nameDefinition: '未按期还款的处罚金',
          collectType: '00',
          num: '0',
          numflag: true,
          val: 'a'
        },
        {
          costName: '提前还款手续费',
          collectTime: '提前还款时收取',
          nameDefinition: '违反合同约定的处罚金',
          collectType: '00',
          num: '0',
          numflag: true,
          val: 'b'
        },
        {
          costName: '宽限期手续费',
          collectTime: '展期时收取',
          nameDefinition: '宽限期（展期）时向融资方收取的费用',
          collectType: '00',
          num: '0',
          numflag: true,
          val: 'd'
        },
        {
          costName: '催收费',
          collectTime: '催收时收取',
          nameDefinition: '产生逾期后催款费用（委托催收、司法诉讼）',
          collectType: '01',
          num: '0',
          numflag: true,
          val: 'e'
        },
        {
          costName: '保理费',
          collectTime: '放款时收取',
          nameDefinition: '保理费',
          collectType: '00',
          num: '0',
          numflag: false,
          val: 'f',
          factoringType: '0'
        },
        {
          costName: '融资利息',
          collectTime: '放款/还款时收取',
          nameDefinition: '融资利息',
          collectType: '00',
          num: '0',
          numflag: false,
          val: 'c',
          preCharge: '01'
        }
      ]
    },
    // 核心企业变化
    coreEpIdChange(e) {
      if (e) {
        if (this.dialogFrom['proFundId']) {
          this.dialogFrom['proFundId'] = ''
          this.dialogFrom['proFundName'] = ''
        }
        if (this.dialogFrom['proFundNo']) this.dialogFrom['proFundNo'] = ''
        if (this.dialogFrom['proFundMainNo']) this.dialogFrom['proFundMainNo'] = ''
        if (this.dialogFrom['bankVersion']) this.dialogFrom['bankVersion'] = ''
        if (this.dialogFrom['bankVersionName']) this.dialogFrom['bankVersionName'] = ''
        if (this.dialogFrom['interstType']) this.dialogFrom['interstType'] = ''
        if (this.dialogFrom['groupCmpName']) this.dialogFrom['groupCmpName'] = ''
        if (this.dialogFrom['groupCmpUnicode']) this.dialogFrom['groupCmpUnicode'] = ''
        this.dialogFrom['groupType'] = 0
        const param = {
          cmpId: e
        }
        console.log(this.coreEnterPriseList)
        this.dialogFrom['coreEpUnicode'] = this.coreEnterPriseList.filter(item => {
          return item['cmpCode'] === e
        })[0]['cmpUnicode']
        getFinancialSideList(param, res => {
          if (res.code === 200) {
            this.cmpNameList = res.data
          }
        })
      }
    },
    async queryFileList(type) {
      this.fileList[type] = await this.queryProCompact({ compactType: type })
      console.log('this.fileList', this.fileList)
      this.$forceUpdate()
    },
    // 资方变化
    proFundIdChange(e) {
      if (this.dialogFrom['proFundNo']) this.dialogFrom['proFundNo'] = ''
      if (this.dialogFrom['proFundMainNo']) this.dialogFrom['proFundMainNo'] = ''
      if (this.dialogFrom['bankVersion']) this.dialogFrom['bankVersion'] = ''
      if (this.dialogFrom['bankVersionName']) this.dialogFrom['bankVersionName'] = ''
      if (this.dialogFrom['interstType']) this.dialogFrom['interstType'] = ''
      this.dialogFrom['proFundUnicode'] = this.cmpNameList.filter(item => {
        return item['id'] === e
      })[0]['cmpUnicode']
      this.dialogFrom['proFundName'] = this.cmpNameList.filter(item => {
        return item['id'] === e
      })[0]['cmpName']
      if (e && this.dialogFrom['coreEpId']) {
        this.selectProFundNoList()
      }
    },
    // 集团模式变化
    groupTypeChange(e) {
      // 资金方
      if (this.dialogFrom['proFundNo']) this.dialogFrom['proFundNo'] = ''
      if (this.dialogFrom['proFundMainNo']) this.dialogFrom['proFundMainNo'] = ''
      if (this.dialogFrom['bankVersion']) this.dialogFrom['bankVersion'] = ''
      if (this.dialogFrom['bankVersionName']) this.dialogFrom['bankVersionName'] = ''
      if (this.dialogFrom['interstType']) this.dialogFrom['interstType'] = ''
      // 资金方产品编码
      if (this.dialogFrom['proFundId']) {
        this.dialogFrom['proFundId'] = ''
        this.dialogFrom['proFundName'] = ''
      }
      // 资金方产品编码list
      //   this.saveFundProdNoListFn([])
      this.dialogFrom['groupCmpName'] = ''
      if (e) {
        this.dialogFrom.coreEpUnicode && this.selectGroupList({
          coreCmpUnicode: this.dialogFrom.coreEpUnicode,
          groupFlag: 3
        })
        this.$forceUpdate()
      } else {
        if (!this.dialogFrom.coreEpId) {
          return
        }
        const param = {
          cmpId: this.dialogFrom.coreEpId
        }
        selectCmpName(param, res => {
          if (res.code === 200) {
            this.cmpNameList = res.data
          }
        })
      }
    },
    selectGroupList(params) {
      this.selectGroupListFn(params).then(res => {
        if (res.code === 200) {
          if (res.data.length <= 0 || !res.data[0].groupCmpUnicode) {
            this.$message({
              type: 'error',
              message: '集团未配置商圈'
            })
            this.cmpNameList = []
            this.saveFundProdNoListFn([])
            return
          }
          this.dialogFrom.groupCmpName = res.data[0].groupCmpName
          this.dialogFrom.groupCmpUnicode = res.data[0].groupCmpUnicode
          // 资金方list
          this.cmpNameList = res.data
          this.$forceUpdate()
        }
      })
    },

    // 查询资金方产品编号列表
    selectProFundNoList() {
      if (this.dialogFrom['proFundNo']) this.dialogFrom['proFundNo'] = ''
      if (this.dialogFrom['proFundMainNo']) this.dialogFrom['proFundMainNo'] = ''
      if (this.dialogFrom['bankVersion']) this.dialogFrom['bankVersion'] = ''
      if (this.dialogFrom['bankVersionName']) this.dialogFrom['bankVersionName'] = ''
      if (this.dialogFrom['interstType']) this.dialogFrom['interstType'] = ''
      if (this.dialogFrom['groupType'] === 1 && !this.dialogFrom['groupCmpUnicode']) {
        this.$message({
          type: 'error',
          message: '此核心企业无所属集团，不能选择集团模式！'
        })
        return
      }
      const param = {
        coreCmpUnicode: this.dialogFrom['groupType'] === 1 ? this.dialogFrom['groupCmpUnicode'] : this.dialogFrom['coreEpUnicode'],
        fundCmpUnicode: this.dialogFrom['proFundUnicode'],
        groupState: this.dialogFrom['groupType']
      }
      this.queryFundProdNo(param)
      this.$forceUpdate()
    },
    // 资金方产品编号变化
    proFundNoChange(e) { // fundProdNo
      if (e) {
        this.dialogFrom['proFundMainNo'] = this.fundProdNoList.filter(item => {
          return item['fundProdNo'] === e
        })[0]['bankVersion']
        this.dialogFrom['bankVersion'] = this.fundProdNoList.filter(item => {
          return item['fundProdNo'] === e
        })[0]['bankVersion']
        this.dialogFrom['bankVersionName'] = this.$codeNew(this.bankVersionList, this.dialogFrom['bankVersion'])
        this.dialogFrom['interstType'] = this.fundProdNoList.filter(item => {
          return item['fundProdNo'] === e
        })[0]['interstType']
        this.dialogFrom['interstType'] = this.dialogFrom['interstType'].toString()
        if (this.dialogFrom['bankVersion']) {
          this.queryFileName(this.dialogFrom['bankVersion'])
        }
        // this.$forceUpdate()
      }
    },
    // 改变产品类别
    changeProCate(proCate) {
      // 当【产品类别】为“征信类”时，【产品品种】默认为“征信”，【授信来源】默认为“机构授信”，【产品品种】、【授信来源】不可编辑，
      // 此时仅需要填写【产品名称】、【产品类别】、【产品品种】、【资金方】。
      // 当【产品类别】非为“征信类”时，【授信来源】默认为“平台授信”且不可编辑，填写全部产品要素。
      if (proCate === '04') {
        // 征信类
        this.dialogFrom['proType'] = '0401' // 【产品品种】默认为“征信”，禁止编辑
        this.dialogFrom['proFundRes'] = '02' // 【授信来源】改为“机构授信”
      } else {
        this.$set(this.dialogFrom, 'proType', proCate + '01')
        // 产品品种可以随意选择
        this.dialogFrom['proFundRes'] = '01' // 【授信来源】改为“平台授信”
      }
    },

    // 清空产品类别
    clearProCate(proCate) {
      this.$set(this.dialogFrom, 'proType', '')
    },

    // 改变是否宽限
    changeProGraceFlag(flag) {
      if (flag === '0') {
        this.$set(this.dialogFrom, 'proGraceTerm', '')
        this.$refs['Forms']['validateField']('proGraceTerm')
      }
    },

    // 收取方式单位变化
    costUnitChange(row) {
      this.checkCostByPlatform(row.costNum, row.costUnit)
      row.minimum = ''
    },
    // 检查最低限额
    checkMinimum(val, unit) {
      // if (unit !== "01"){
      //     if (val&&isNaN(Number(val))) {
      //         this.$message({
      //             type: "error",
      //             message: "请输入正确的收取方式"
      //         });
      //         this.validFlag = false;
      //     }else if (val&&
      //         val.toString().charAt("0") == "0" &&
      //         val.toString().charAt("1") != "." &&
      //         val.length > 1
      //     ) {
      //         this.$message({
      //             type: "error",
      //             message: "请输入正确的收取方式"
      //         });
      //         this.validFlag = false;
      //     }else if (val&&!/^(\d+)(\.\d{1,5})?$/.test(val)) {
      //         this.$message({
      //             type: "error",
      //             message: "请保留1-5位小数"
      //         });
      //         this.validFlag = false;
      //     }else{
      //         this.validFlag = true;
      //     }
      // }
    },
    // 校验费用输入框
    checkCostByPlatform(val, unit) {
      //     if (val === "" || val === undefined || val === null) {
      //         this.$message({
      //             type: "error",
      //             message: "请输入收取方式"
      //         });
      //         this.validFlag = false;
      //     } else if (isNaN(Number(val))) {
      //         this.$message({
      //             type: "error",
      //             message: "请输入正确的收取方式"
      //         });
      //         this.validFlag = false;
      //     } else if (
      //         val.toString().charAt("0") == "0" &&
      //         val.toString().charAt("1") != "." &&
      //         val.length > 1
      //     ) {
      //         this.$message({
      //             type: "error",
      //             message: "请输入正确的收取方式"
      //         });
      //         this.validFlag = false;
      //     } else if (unit == "01") {
      //         //单位：笔
      //         if (!/^(\d+)(\.\d{1,2})?$/.test(val)) {
      //             this.$message({
      //                 type: "error",
      //                 message: "请保留1-2位小数"
      //             });
      //             this.validFlag = false;
      //         } else if (val.length - 1 > 18) {
      //             this.$message({
      //                 type: "error",
      //                 message: "金额不得超过18位"
      //             });
      //             this.validFlag = false;
      //         } else {
      //             this.validFlag = true;
      //         }
      //     } else {
      //         //单位：比例
      //         if (!/^(\d+)(\.\d{1,5})?$/.test(val)) {
      //             this.$message({
      //                 type: "error",
      //                 message: "请保留1-5位小数"
      //             });
      //             this.validFlag = false;
      //         } else if (val > 100) {
      //             this.$message({
      //                 type: "error",
      //                 message: "比例不能大于100"
      //             });
      //             this.validFlag = false;
      //         } else if (val.length - 1 > 8) {
      //             this.$message({
      //                 type: "error",
      //                 message: "比例不得超过8位"
      //             });
      //             this.validFlag = false;
      //         } else {
      //             this.validFlag = true;
      //         }
      //     }
    },

    // 提交-保存的时候检查每个输入框
    checkAmtRule() {
      let flag1 = false
      let flag2 = false
      // 费用-资金方
      for (var i = 0; i < this.costCapitalSideList.length; i++) {
        const oneitem = this.costCapitalSideList[i]
        if (
          isNaN(Number(oneitem.num)) ||
                    oneitem.num === '' ||
                    oneitem.num === undefined ||
                    oneitem.num === null ||
                    (oneitem.num.charAt('0') === '0' &&
                        oneitem.num.charAt('1') !== '.' &&
                        oneitem.num.length > 1)
        ) {
          flag1 = false
          break
        } else if (oneitem.collectType === '01') {
          // 单位：笔
          if (
            !/^(\d+)(\.\d{1,2})?$/.test(oneitem.num) ||
                        oneitem.num.length - 1 > 18
          ) {
            flag1 = false
            break
          } else {
            flag1 = true
            continue
          }
        } else if (oneitem.collectType === '00') {
          // 单位：比例
          if (
            !/^(\d+)(\.\d{1,5})?$/.test(oneitem.num) ||
                        oneitem.num.length - 1 > 8 ||
                        oneitem.num > 100
          ) {
            flag1 = false
            break
          } else {
            flag1 = true
            continue
          }
        } else {
          flag1 = true
        }
      }

      // 费用-平台
      var costPlatformList = this.dialogFrom.flist
      // if (this.dialogStaus == "2") {
      // 编辑页面进入
      // costPlatformList = this.dialogFrom.cosePlatfromList;
      // } else {
      //     costPlatformList = this.startCostList.rlist;
      // }
      if (costPlatformList.length > 0) {
        for (var j = 0; j < costPlatformList.length; j++) {
          const twoitem = costPlatformList[j]
          if (
            isNaN(Number(twoitem.costNum)) ||
                        twoitem.costNum === '' ||
                        twoitem.costNum === undefined ||
                        twoitem.costNum === null ||
                        (twoitem.costNum.toString().charAt('0') === '0' &&
                            twoitem.costNum.toString().charAt('1') !== '.' &&
                            twoitem.costNum.length > 1)
          ) {
            flag2 = false
            break
          } else if (twoitem.costUnit === '01') {
            // 单位：笔
            if (
              !/^(\d+)(\.\d{1,2})?$/.test(twoitem.costNum) ||
                            twoitem.costNum.length - 1 > 18
            ) {
              flag2 = false
              break
            } else {
              flag2 = true
              continue
            }
          } else if (twoitem.costUnit === '00') {
            // 单位：比例
            if (
              !/^(\d+)(\.\d{1,5})?$/.test(twoitem.costNum) ||
                            twoitem.costNum.length - 1 > 8 ||
                            twoitem.costNum > 100
            ) {
              flag2 = false
              break
            } else {
              flag2 = true
              continue
            }
          } else {
            flag2 = true
          }
        }
      } else {
        flag2 = true
      }
      // console.log(flag1,flag2)
      if (this.dialogFrom['fundConfigType'] === 0) {
        return flag2
      }
      return flag1 && flag2
    },

    // 提交
    handleSubmit() {
      var that = this
      const compactArr = []
      for (const item in that.dialogFrom) {
        if (item.slice(0, 5) === 'agree') {
          let flag = 0
          for (let i = 0; i < that.fileNameList.length; i++) {
            if (that.fileNameList[i].dictId === that.dialogFrom[item]['compactType']) {
              flag = 1
              break
            }
          }
          if (flag || that.dialogFrom[item]['compactType'] === '10') {
            compactArr.push({
              compactType: that.dialogFrom[item]['compactType'],
              compactName: that.dialogFrom[item]['compactName'],
              fileName: that.dialogFrom[item]['fileName'],
              compactCode: that.dialogFrom[item]['compactId']
                ? that.dialogFrom[item]['compactId']
                : that.dialogFrom[item]['compactCode']
            })
          }
        }
      }
      console.log(that.dialogFrom, compactArr)

      const $ele = this.$refs['Forms']
      $ele.validate(valid => {
        if (valid) {
          if (!that.dialogFrom.proFundNo) {
            that.$message({
              type: 'error',
              message: '请选择资金方产品编号'
            })
            return
          }
          if (that.dialogFrom.flist.length <= 0) {
            that.$message({
              type: 'error',
              message: '平台费用不能为空'
            })
            return
          }
          // 费用-资金方 的上送参数
          this.costCapitalSideList.forEach((item, index) => {
            if (
              item.val === 'a' ||
                            item.val === 'b' ||
                            item.val === 'd' ||
                            item.val === 'e'
            ) {
              this.dialogFrom['proCost' + item.val] =
                                item.num + ',' + item.collectType
            } else if (item.val === 'f') {
              // 保理费
              this.dialogFrom['proCostf'] = item.factoringType
            } else if (item.val === 'c') {
              // 融资利息
              this.dialogFrom['preCharge'] = item.preCharge
            }
          })
          if (!this.checkAmtRule()) {
            this.$message({
              type: 'error',
              message: '请输入正确的收取方式'
            })
          } else {
            this.dialogFrom['compactList'] = compactArr
            const newDialogForm1 = JSON.parse(JSON.stringify(this.dialogFrom))

            const newDialogForm = Object.keys(newDialogForm1).filter(v => {
              return v.indexOf('agree') === -1
            }).reduce(function(newData, k) {
              newData[k] = newDialogForm1[k]
              return newData
            }, {})

            delete newDialogForm['groupType']
            delete newDialogForm['proFundUnicode']
            delete newDialogForm['coreEpUnicode']
            delete newDialogForm['bankVersionName']
            delete newDialogForm['bankCode']
            newDialogForm['flist'].forEach(v => {
              delete v.costTypeList
            })

            newDialogForm['flist'].forEach(item => {
              delete item.proId
            })
            console.log('编辑提交', newDialogForm)

            // //费用-平台 的上送参数
            // newDialogForm["flist"] = [];
            // this.dialogFrom.cosePlatfromList.forEach((item, index) => {
            //     newDialogForm["flist"].push({
            //         costId: item.costId,
            //         costNum: item.costNum,
            //         costUnit: item.costUnit,
            //         minimum: item.minimum*1||''
            //     });
            // });
            console.log(newDialogForm)
            // return
            // 编辑
            UpdateSubmitProduct(newDialogForm, res => {
              const code = res.code
              if (code === 200) {
                that.$message({
                  type: 'success',
                  message: res.msg
                })
                this.$router.go(-1)
              }
            })
          }
        } else {
          console.log('error submit!!')
          that.$message({
            type: 'error',
            message: '页面输入项有误，请检查'
          })
          return false
        }
      })
    },

    // 保存
    handleSave() {
      var that = this
      const compactArr = []
      console.log(this.dialogFrom.proFundNo)
      for (const item in this.dialogFrom) {
        if (item.slice(0, 5) === 'agree') {
          let flag = 0
          for (let i = 0; i < that.fileNameList.length; i++) {
            if (that.fileNameList[i].dictId === that.dialogFrom[item]['compactType']) {
              flag = 1
              break
            }
          }
          if (flag || that.dialogFrom[item]['compactType'] === '10') {
            compactArr.push({
              compactType: that.dialogFrom[item]['compactType'],
              compactName: that.dialogFrom[item]['compactName'],
              fileName: that.dialogFrom[item]['fileName'],
              compactCode: that.dialogFrom[item]['compactId']
                ? that.dialogFrom[item]['compactId']
                : that.dialogFrom[item]['compactCode']
            })
          }
        }
      }
      const $ele = this.$refs['Forms']
      $ele.validate(valid => {
        if (valid) {
          if (!that.dialogFrom.proFundNo) {
            that.$message({
              type: 'error',
              message: '请选择资金方产品编号'
            })
            return
          }
          if (that.dialogFrom.flist.length <= 0) {
            that.$message({
              type: 'error',
              message: '平台费用不能为空'
            })
            return
          }
          // 费用-资金方 的上送参数
          this.costCapitalSideList.forEach((item, index) => {
            if (
              item.val === 'a' ||
                            item.val === 'b' ||
                            item.val === 'd' ||
                            item.val === 'e'
            ) {
              this.dialogFrom['proCost' + item.val] =
                                item.num + ',' + item.collectType
            } else if (item.val === 'f') {
              // 保理费
              this.dialogFrom['proCostf'] = item.factoringType
            } else if (item.val === 'c') {
              // 融资利息
              this.dialogFrom['preCharge'] = item.preCharge
            }
          })
          if (!this.checkAmtRule()) {
            this.$message({
              type: 'error',
              message: '请输入正确的收取方式'
            })
          } else {
            this.dialogFrom['compactList'] = compactArr
            const newDialogForm1 = JSON.parse(JSON.stringify(this.dialogFrom))
            const newDialogForm = Object.keys(newDialogForm1).filter(v => {
              return v.indexOf('agree') === -1
            }).reduce(function(newData, k) {
              newData[k] = newDialogForm1[k]
              return newData
            }, {})
            delete newDialogForm['groupType']
            delete newDialogForm['proFundUnicode']
            delete newDialogForm['coreEpUnicode']
            delete newDialogForm['bankVersionName']
            delete newDialogForm['bankCode']
            newDialogForm['flist'].forEach(v => {
              delete v.costTypeList
            })

            // //费用-平台 的上送参数
            // newDialogForm["flist"] = [];
            // this.dialogFrom.cosePlatfromList.forEach((item, index) => {
            //     newDialogForm["flist"].push({
            //         costId: item.costId,
            //         costNum: item.costNum,
            //         costUnit: item.costUnit,
            //         minimum: item.minimum*1||''
            //     });
            // });
            console.log('编辑保存', newDialogForm)
            // 编辑
            UpdateProduct(newDialogForm, res => {
              const code = res.code
              if (code === 200) {
                that.$message({
                  type: 'success',
                  message: res.msg
                })
                this.$router.go(-1)
              }
            })
          }
        } else {
          console.log('error submit!!')
          that.$message({
            type: 'error',
            message: '页面输入项有误，请检查'
          })
          return false
        }
      })
    },
    handleCancel() {
      const $ele = this.$refs['Forms']
      $ele.resetFields()
      // $ele.clearValidate();
      this.SaveBtnloadingFN(false)
      this.close()
    },

    beforeClose(done) {
      const $ele = this.$refs['Forms']
      $ele.resetFields()
      // $ele.clearValidate();
      this.SaveBtnloadingFN(false)
      done()
    },

    // 产品品种改变
    changeProType(proType) {
      // console.log(proType, 'proType');
      this.proTypeShow = proType !== '0102'
    }
  }
}
</script>
<style lang="scss">
    .input-padding-box{
        input{
            padding: 0 6px;
        }
    }
    .product-edit{
        .br-table{
            .el-form-item{
                /*margin-bottom: 0;*/
            }
            .platfrom-input{
                .el-input__inner{
                    height: 30px;
                    line-height: 30px;
                    font-size: 12px;
                }
            }
        }

    }
</style>
<style lang="scss" scoped>
    .product-edit {
        background-color: #fff;
        padding: 14px 18px;
        .br-footer{
            text-align: center;
            margin: 20px 0;
        }
        .add-platfrom-btn{
            text-align: right;
            margin: -40px 10px 20px;
        }
        .mini-input,
        .mini-select {
            width: 95px;
        }
        .mini-select {
            margin-left: 10px;
        }
    }

    .br-input,
    .br-select,.br-radio {
        width: 160px;
    }

    .br-input-small {
        width: 58px;
    }

    .br-select-medium {
        width: 90px;
        margin: 0 8px;
    }

    .br-input-medium {
        width: 140px;
    }

    .br-right-label {
        font-size: 12px;
        margin: 0 5px;
    }

    .parameTitle {
        height: 20px;
        line-height: 20px;
        margin: 15px 0;
    }

    .leftLine {
        width: 3px;
        height: 20px;
        display: inline-block;
        background: #4da1ff;
    }

    .rightText {
        font-size: 14px;
        color: #333;
        padding-left: 12px;
    }

    .red_info {
        color: #f00;
        font-size: 13px;
        margin-left: 5px;
        font-weight: normal;
        display: block;
        padding: 0 0 10px 20px;
    }

    .br-table {
        /deep/ .el-input__icon {
            line-height: 0;
        }
    }

    /deep/ .el-form-item__error {
        white-space: nowrap;
    }
</style>
